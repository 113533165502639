<template>
  <b-modal size="lg" v-model="showModalBroadcast" title="Create New Broadcast" hide-footer @hide="onHide">
    <b-form @submit.prevent="doBroadcastSegment" @reset="closeModalBroadcast">
      <b-form-group label="Title">
        <b-input
          v-model="v$.form.title.$model"
          placeholder="Input broadcast title"
          type="text"/>
        <b-form-invalid-feedback :state="!v$.form.title.$invalid" v-if="v$.form.title.$errors[0]">
          <span> {{ v$.form.title.$errors[0].$message }}</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Message">
        <b-textarea
          v-model="v$.form.message.$model"
          placeholder="Input broadcast message"
          rows="3"
          type="text"/>
        <b-form-invalid-feedback :state="!v$.form.message.$invalid" v-if="v$.form.message.$errors[0]">
          <span> {{ v$.form.message.$errors[0].$message }}</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Target">
        <template>
          <el-select class="w-full" v-model="form.fcm_target" placeholder="Select segment">
            <el-option v-for="(item, index) in segment_test" :value="item.value" :key="index" :label="item.label"/>
          </el-select>
        </template>
      </b-form-group> 
      <b-form-group label="Recipient Segment" v-if="form.fcm_target === 'device'">
        <el-select
          class="w-full"
          v-model="v$.form.recipient_type.$model"
          @change="handlerChangeSegment"
          placeholder="Select recipient segmentation type">
          <el-option v-for="(item, index) in segmentation_options" :value="item.value" :key="index" :label="item.label"/>
        </el-select>
        <b-form-invalid-feedback :state="!v$.form.recipient_type.$invalid" v-if="v$.form.recipient_type.$errors[0]">
          <span> {{ v$.form.recipient_type.$errors[0].$message }}</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Image">
        <b-form-file
          v-model="form.image"
          class="mt-2 border-1 p-2"
          accept=".png, .jpeg, .jpg"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          @change="handlerImageUpload"
        ></b-form-file>
        </b-form-group>
      <b-form-group label="Start Date" v-if="isCustomSegment && form.fcm_target === 'device'">
        <el-date-picker
          class="w-full"
          v-model="v$.form.start_date.$model"
          type="date"
          placeholder="Pick start date"/>
        <b-form-invalid-feedback :state="!v$.form.start_date.$invalid" v-if="v$.form.start_date.$error">
          <span> {{ v$.form.start_date.$errors[0].$message }}</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="End Date" v-if="isCustomSegment && form.fcm_target === 'device' ">
        <el-date-picker
          class="w-full"
          v-model="v$.form.end_date.$model"
          type="date"
          placeholder="Pick start date"/>
        <b-form-invalid-feedback :state="!v$.form.end_date.$invalid" v-if="v$.form.end_date.$error">
          <span> {{ v$.form.end_date.$errors[0].$message }}</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Recipient File" v-if="form.recipient_type == 'csv' && form.fcm_target === 'device'">
        <b-form-file
          v-model="form.file"
          @input="handlerInputFile"
          :state="Boolean(form.file)"
          class="mt-2 border-1 p-2 text-sm"
          accept=".csv"
          placeholder="Choose a file or drop it here..."
          drop-placeholder=" "
        ></b-form-file>
        <b-form-invalid-feedback :state="false">
          <div class="w-full flex flex-row justify-content-between">
            <span>*only .csv file allowed</span>
            <a href="/sample/csv_user_notif.csv" download><font-awesome-icon icon="download"/> Download example file</a>
          </div>
        </b-form-invalid-feedback>
        <b-form-invalid-feedback :state="!v$.form.recipients.$invalid" v-if="v$.form.recipients.$errors[0]">
          <span>{{ v$.form.recipients.$errors[0].$message }}</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Company" v-if="form.recipient_type == 'private' && form.fcm_target === 'device'">
        <el-select
          class="w-full"
          filterable
          remote
          @change="handlerCompanyChange"
          :loading="loading.company"
          :remote-method="searchCompany"
          v-model="form.company_id"
          multiple=""
          placeholder="Select company">
          <el-option value="" label="All">All</el-option>
          <el-option v-for="(item, index) in companies.rows" :value="item.company_id" :key="index" :label="item.company_name"/>
        </el-select>
      </b-form-group>
       <b-form-group label="Broadcast Date">
        <el-radio-group @change="() => v$.schedule.$touch()" v-model="form.is_scheduled" size="small">
          <el-radio-button :label="false">Now</el-radio-button>
          <el-radio-button :label="true">Later</el-radio-button>
        </el-radio-group>
      </b-form-group>
       <b-form-group label="Choose Broadcast Date" v-if="form.is_scheduled">
        <div class="row">
          <div class="col-md-4">
            <el-date-picker
              v-model="v$.schedule.date.$model"
              :picker-options="datePickerOptions"
              type="date"
              size="small"
              @change="handlerChangeBroadcastDate"
              value-format="yyyy-MM-dd"
              placeholder="Pick a day">
            </el-date-picker>
            <b-form-invalid-feedback :state="!v$.schedule.date.$invalid" v-if="v$.schedule.date.$errors[0]">
              <span> {{ v$.schedule.date.$errors[0].$message }}</span>
            </b-form-invalid-feedback>
          </div>
          <div class="col-md-4 ml-2">
            <el-time-select
              v-model="schedule.time"
              :picker-options="timePickerOptions"
              size="small"
              value-format="HH:mm"
              placeholder="Pick a time">
            </el-time-select>
            <b-form-invalid-feedback :state="!v$.schedule.time.$invalid" v-if="v$.schedule.time.$errors[0]">
              <span> {{ v$.schedule.time.$errors[0].$message }}</span>
            </b-form-invalid-feedback>
          </div>
        </div>
      </b-form-group> 
      <b-form-group label="Recipient Segment" v-if="form.fcm_target === 'topic'">
        <template>
          <el-select class="w-full" v-model="form.recipient_type"  @change="handlerChangeTopic" placeholder="Select Recipient Segment">
            <el-option v-for="(item, index) in topic_options" :value="item.value" :key="index" :label="item.label"/>
          </el-select>
        </template>
      </b-form-group> 
      <b-form-group label="Company" v-if="form.recipient_type == 'private' && form.fcm_target === 'topic'">
        <el-select
          class="w-full" filterable remote v-model="form.company_id"  multiple="" @change="handlerCompanyChange" :loading="loading.company" :remote-method="searchCompany" placeholder="Select company">
          <el-option value="" label="All">All</el-option>
          <el-option v-for="(item, index) in companies.rows" :value="item.company_id" :key="index" :label="item.company_name"/>
        </el-select>
      </b-form-group>
      <b-form-group label="Page to Open" v-if="form.fcm_target === 'topic'">
        <template >
          <el-select
            class="w-full"
            @change="pageDestinationChangeHandler"
            v-model="form.page_destination"
            placeholder="Select page destination">
            <el-option v-for="(item, index) in destination_options" :value="item.value" :key="index" :label="item.label"/>
          </el-select>
        </template>
      </b-form-group> 
      <b-form-group label="UTM Target" v-if="['/voucher', '/merchant', '/promo'].includes(form.page_destination) && form.fcm_target === 'topic'">
        <el-select
          class="w-full"
          filterable
          remote
          @change="targetChangeHandler"
          :loading="targetLoading"
          :remote-method="targetFilterHandler"
          v-model="form.target"
          placeholder="Select target">
          <el-option v-for="(item, index) in target_list" :value="item.value" :key="index" :label="item.label"/>
        </el-select>
      </b-form-group>
      <b-form-group label="UTM Target" v-if="form.page_destination === 'custom' && form.fcm_target === 'topic'">
        <b-input-group>
          <template #prepend>
            <b-input-group-text>
              {{ deepLinkBaseURL }}/
            </b-input-group-text>
          </template>
          <b-input
            v-model="form.target"
            placeholder="Input custom target link. eg: /merchant"
            type="text"/>
        </b-input-group>
      </b-form-group>


      <b-form-group label="Page to Open" v-if="form.fcm_target === 'device'">
        <template v-if="form.page_destination !== '/promo'">
          <el-select
            class="w-full"
            @change="pageDestinationChangeHandler"
            v-model="form.page_destination"
            placeholder="Select page destination">
            <el-option v-for="(item, index) in destination_options" :value="item.value" :key="index" :label="item.label"/>
          </el-select>
        </template>
        <template v-else>
          <el-select
            :disabled="true"
            class="w-full"
            @change="pageDestinationChangeHandler"
            v-model="form.page_destination"
            placeholder="Select page destination">
            <el-option v-for="(item, index) in destination_options" :value="item.value" :key="index" :label="item.label"/>
          </el-select>
        </template>
      </b-form-group> 
      <b-form-group label="Target" v-if="['/voucher', '/merchant', '/promo'].includes(form.page_destination) && form.fcm_target === 'device'">
        <el-select
          class="w-full"
          filterable
          remote
          @change="targetChangeHandler"
          :loading="targetLoading"
          :remote-method="targetFilterHandler"
          v-model="form.target"
          placeholder="Select target">
          <el-option v-for="(item, index) in target_list" :value="item.value" :key="index" :label="item.label"/>
        </el-select>
      </b-form-group>
      <b-form-group label="Target" v-if="form.page_destination === 'custom' && form.fcm_target === 'device'">
        <b-input-group>
          <template #prepend>
            <b-input-group-text>
              {{ deepLinkBaseURL }}/
            </b-input-group-text>
          </template>
          <b-input
            v-model="form.target"
            placeholder="Input custom target link. eg: /merchant"
            type="text"/>
        </b-input-group>
      </b-form-group>
      <b-form-group label="UTM Medium">
        <b-input v-model="utm.utm_medium" placeholder="Input UTM Medium" type="text"/>
      </b-form-group>
      <b-form-group label="UTM Campaign">
        <b-input v-model="utm.utm_campaign" placeholder="UTM Campaign" type="text"/>
      </b-form-group>
      <div class="d-flex flex-row float-right">
        <el-button @click="doBroadcastSegment" :loading="loading.broadcast" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
        <el-button @click="closeModalBroadcast" size="small" class="mr-2">Cancel</el-button>
      </div>
    </b-form>
  </b-modal>
</template>
<script>
import moment from 'moment';
import useVuelidate from '@vuelidate/core';
import { required, requiredIf } from '@vuelidate/validators';
import popupErrorMessages from '@/library/popup-error-messages';
import {
  CREATE_BROADCAST,
} from '@/store/modules/broadcast';
import {
  GET_COMPANIES,
} from '@/store/modules/companies';
import {
  isEmpty, uniqBy, clone,
} from 'lodash';
import url from 'url';
import { GET_MERCHANTS } from '@/store/modules/merchants';
import { GET_VOUCHERS } from '@/store/modules/vouchers';
import { GET_PROMOS } from '@/store/modules/promo';

const deepLinkBaseURL = 'app://app.fortuna.venteny';

export default {
  name: 'CreateUpdateBroadcast',
  props: {
    show: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      form: {
        title: { required },
        message: { required },
        recipient_type: { required },
        start_date: { required: requiredIf(this.isCustomSegment) },
        end_date: { required: requiredIf(this.isCustomSegment) },
        recipients: { required: requiredIf(this.recipient_type === 'csv') },
      },
      schedule: {
        date: { required: requiredIf(this.form.is_scheduled) },
        time: { required: requiredIf(this.form.is_scheduled) },
      },
      loading: {
        broadcast: false,
      },
    };
  },
  computed: {
    isCustomSegment() {
      return this.form.recipient_type.indexOf('custom') !== -1;
    },
    timePickerOptions() {
      const now = () => moment();
      return {
        step: '00:30',
        start: moment(this.schedule.date).isSame(now(), 'day') ? now().add(30, 'minute').format(`HH:${now().format('mm') > 30 ? '00' : '30'}`) : '00:00',
        end: '24:00',
        format: 'HH:mm',
      };
    },
  },
  data() {
    return {
      deepLinkBaseURL,
      form: {
        company_id: '',
        image: '',
        title: '',
        message: '',
        recipient_type: '',
        type: 'text',
        start_date: '',
        end_date: '',
        fcm_target: 'topic',
        file: null,
        utm: '',
        recipients: '',
        page_destination: '',
        target: '',
        is_scheduled: false,
      },
      utm : {
        utm_campaign: '',
        utm_medium: '',
        utm_source: ''
      },
      schedule: {
        date: '',
        time: '',
      },
      datePickerOptions: {
        disabledDate: (date) => moment(date).isBefore(moment(), 'days'),
        shortcuts: [
          {
            text: 'Today',
            onClick(picker) {
              picker.$emit('pick', new Date());
            },
          },
          {
            text: 'Tommorow',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24);
              picker.$emit('pick', date);
            },
          },
          {
            text: 'Next Week',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            },
          },
          {
            text: 'Next Month',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 30);
              picker.$emit('pick', date);
            },
          },
        ],
      },
      timeoutSearchCompany: null,
      timeoutTargetFilter: null,
      companies: {
        rows: [],
      },
      tempTarget: {},
      target_list: [],
      targetLoading: false,
      showModalBroadcast: false,
      segmentation_options: [
        {
          value: 'private',
          label: 'Private User',
        },
        {
          value: 'public',
          label: 'Public User',
        },
        {
          value: 'segment_yesterday_register',
          label: 'Registered Yesterday',
        },
        {
          value: 'segment_yesterday_transaction',
          label: 'Do Transaction Yesterday',
        },
        {
          value: 'segment_today_register',
          label: 'Registered Today',
        },
        {
          value: 'segment_today_transaction',
          label: 'Do Transaction Today',
        },
        {
          value: 'segment_month_register',
          label: 'Registered This Month',
        },
        {
          value: 'segment_month_transaction',
          label: 'Do Transaction This Month',
        },
        {
          value: 'segment_lastmonth_register',
          label: 'Registered Last Month',
        },
        {
          value: 'segment_lastmonth_transaction',
          label: 'Do Transaction Last Month',
        },
        {
          value: 'segment_custom_register',
          label: 'Custom Register Date',
        },
        {
          value: 'segment_custom_transaction',
          label: 'Custom Transaction Date',
        },
        {
          value: 'csv',
          label: 'CSV',
        },
        {
          value: 'promo',
          label: 'Promo',
        },
      ],
      destination_options: [
        {
          value: '',
          label: 'Home',
        },
        {
          value: 'custom',
          label: 'Custom',
        },
        {
          value: '/merchant',
          label: 'Merchant Voucher List',
        },
        {
          value: '/voucher',
          label: 'Voucher',
        },
        {
          value: '/history',
          label: 'Transaction History',
        },
        {
          value: '/promo',
          label: 'Promo',
        },
      ],
      destination_fcm_options: [
        {
          value: '/merchant',
          label: 'Merchant Voucher List',
        },
        {
          value: '/voucher',
          label: 'Voucher',
        },
        {
          value: '/promo',
          label: 'Promo',
        },
      ],
      segment_test: [
        {
          value: 'device',
          label: 'Device',
        },
        {
          value: 'topic',
          label: 'Topic',
        },
      ],
      topic_options: [
        {
          value: 'all',
          label: 'All',
        },
        {
          value: 'private',
          label: 'Private',
        },
        {
          value: 'public',
          label: 'Public',
        },
      ],
      loading: {
        company: false,
        broadcast: false,
      },
    };
  },
  methods: {
    onHide() {
      this.$emit('hide');
    },
    async doBroadcastSegment() {
      this.loading.broadcast = true;
      const obj = clone(this.form);
      delete obj.file;
      const deeplink_query = {};
      if (obj.page_destination === '/voucher') {
        deeplink_query.voucher_id = obj.target;
      } else if (obj.page_destination === '/merchant') {
        deeplink_query.merchant_id = obj.target;
      } else if (obj.page_destination === '/promo') {
        deeplink_query._id = obj.target; 
      } else if (obj.page_destination === 'custom') {
        obj.page_destination = `/${obj.target}`;
      }
      obj.deeplink = `${deepLinkBaseURL}${url.format({ pathname: obj.page_destination, query: deeplink_query })}`;
      const utm_query = {
          ...deeplink_query,
          utm_source: 'owned',
          utm_medium: this.utm.utm_medium,
          utm_campaign: this.utm.utm_campaign
      };
      obj.utm = `${deepLinkBaseURL}${url.format({
          pathname: obj.page_destination,
          query: utm_query
      })}`;
      if (obj.is_scheduled) {
        obj.schedule_date = `${[this.schedule.date, this.schedule.time].join(' ')}:00`;
      }
      // const all_value_index = obj.company_id.findIndex((v) => v === '');
      // if (all_value_index > -1) {
      //   obj.company_id = [];
      // }
      delete obj.page_destination;
      delete obj.target;

      await this.$store.dispatch(CREATE_BROADCAST, obj)
        .then(() => {
          this.$message({
            title: 'Success',
            type: 'success',
            message: 'Broadcast success',
          });
          this.closeModalBroadcast();
          this.$parent.getList();
        }).catch((err) => {
          console.error('Error in doBroadcast:', err);
          popupErrorMessages(err?.response?.data);
        });
      this.loading.broadcast = false;
    },
    closeModalBroadcast() {
      this.onHide();
      this.form = {
        image: '',
        title: '',
        message: '',
        recipient_type: '',
        type: 'text',
        start_date: '',
        end_date: '',
        recipients: '',
        page_destination: '',
        target: '',
        company_id: [],
        utm: '',
        fcm_target: 'device',
      };
      this.utm = {
        utm_campaign: '',
        utm_medium: '',
        utm_source: ''
      }
      this.showModalRecipientTable = false
    },
    handlerChangeSegment(value) {
      if (value === 'private') {
        this.form.company_id = [];
        this.loadCompanies();
      }
    },
     handlerChangeTopic(value) {
      // if (value === 'private') {
        this.form.company_id = [];
        this.loadCompanies();
      // }
    },
    async loadCompanies({ search_keyword = '' } = {}) {
      this.loading.company = true;
      await this.$store.dispatch(GET_COMPANIES, {
        search_keyword,
      });
      this.loading.company = false;
      const { rows } = this.$store.getters.companies;
      this.companies.rows = this.companies.rows.filter((v) => this.form.company_id.includes(v.company_id));
      this.companies.rows.push(...rows);
    },
    searchCompany(value) {
      clearTimeout(this.timeoutSearchCompany);
      this.timeoutSearchCompany = setTimeout(() => {
        this.loadCompanies({
          search_keyword: value,
        });
      }, 1000);
    },
    async handlerInputFile() {
      if (!this.form.file) return;
      try {
        const fileContent = await this.readFileAsText(this.form.file);
        const base64Content = this.encodeToBase64(fileContent);
        this.form.recipients = `data:text/csv;base64,${base64Content}`;
      } catch (error) {
        console.error('Error reading file:', error);
      }
    },
    readFileAsText(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    encodeToBase64(str) {
      return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_, p1) => String.fromCharCode(`0x${p1}`)));
    },
    pageDestinationChangeHandler(value) {
      this.tempTarget = {};
      this.form.target = '';
      if (value === '/merchant') {
        this.getMerchantList();
      } else if (value === '/voucher') {
        this.getVoucherList();
      } else if (value === '/promo') {
        this.getListPromo();
      }
    },
    pageDestinationChangeHandlerUTM(value) {
      this.tempTarget = {};
      this.form.target = '';
      // this.form.target_utm = '';
      if (value === '/merchant') {
        this.getMerchantList();
      } else if (value === '/voucher') {
        this.getVoucherList();
      } else if (value === '/promo') {
        this.getListPromo();
      }
    },
    targetFilterHandler(value) {
      clearTimeout(this.timeoutTargetFilter);
      this.timeoutTargetFilter = setTimeout(() => {
        if (this.form.page_destination === '/merchant') this.getMerchantList(value);
        if (this.form.page_destination === '/voucher') this.getVoucherList(value);
        if (this.form.page_destination === '/promo') this.getListPromo(value);
      }, 500);
    },
    targetChangeHandler(value) {
      this.tempTarget = this.target_list.find((v) => v.value === value);
    },
    async getMerchantList(keyword) {
      this.targetLoading = true;
      await this.$store.dispatch(GET_MERCHANTS, {
        search_by: 'merchant_name',
        search_keyword: keyword,
        status_filter: 'active',
      }).catch(() => {});
      this.target_list = [{
        label: 'No Target',
        value: '',
      }];
      if (!isEmpty(this.tempTarget)) {
        this.target_list.push(this.tempTarget);
      }
      const { rows } = this.$store.getters.merchants;
      this.target_list.push(...rows.map((v) => ({ value: v.merchant_id, label: v.merchant_name })));
      this.target_list = uniqBy(this.target_list, 'value');
      this.targetLoading = false;
    },
    async getVoucherList(keyword = '') {
      this.targetLoading = true;
      await this.$store.dispatch(GET_VOUCHERS, {
        page: 1,
        per_page: 20,
        search_by: 'voucher_name',
        search_keyword: keyword,
        status_filter: 'active',
      }).catch(() => {});
      this.target_list = [{
        label: 'No Target',
        value: '',
      }];
      if (!isEmpty(this.tempTarget)) {
        this.target_list.push(this.tempTarget);
      }
      const { rows } = this.$store.getters.vouchers;
      this.target_list.push(...rows.map((v) => ({ value: v.voucher_id, label: v.voucher_name })));
      this.target_list = uniqBy(this.target_list, 'value');
      this.targetLoading = false;
    },
    async getListPromo() {
      this.targetLoading = true;
      await this.$store.dispatch(GET_PROMOS, {
        search_by: 'promo_status',
        search_keyword: 1,
      }).catch(() => {});
      this.target_list = [{
        label: 'No Target',
        value: '',
      }];
      if (!isEmpty(this.tempTarget)) {
        this.target_list.push(this.tempTarget);
      }
      const { rows } = this.$store.getters.promos;
      this.target_list.push(...rows.map((v) => ({ value: v._id, label: v.promo_name })));
      this.target_list = uniqBy(this.target_list, 'value');
      this.targetLoading = false;
    },
    handlerCompanyChange(value) {
      if (value.includes('')) {
        const allCompanyIds = this.companies.rows.map(company => company.company_id);
        this.form.company_id = allCompanyIds;
      } else {
        this.form.company_id = value.filter(v => v !== '');
      }
    },
    handlerImageUpload(event) {
      const file = event.target.files[0];
      this.convertImageToURL(file);
    },
    convertImageToURL(file) {
      const reader = new FileReader();
      reader.onload = () => {
        const imageURL = reader.result; 
        this.form.image = imageURL;
      };
      reader.readAsDataURL(file); 
    },
    changeHandlerPromo(value) {
      if (value.includes('all')) {
        this.form.promo = this.listPromo.map((v) => {
          if(v.promo_name != 64) return v.promo_name
        });
      }
    },
    handlerPromoChange(value) {
      if (value.includes('')) {
        const allPromoNames = this.listPromo.map(promo => promo.promo_name);
        this.form.promo = allPromoNames;
      } else {
        this.form.promo = value.filter(v => v !== '');
      }
    },
    searchPromo(query) {
      this.timeout = setTimeout(() => {
        this.listPromo.search_keyword = query;
        this.getListPromo
      }, 1000);
    },
    async mounted() {
      this.getListPromo();
    },
  },
  /* eslint-disable object-shorthand */
  watch: {
    'schedule.date'() {
      if (moment(this.schedule.date).isSame(moment(), 'days')) {
        if (this.schedule.time && moment(this.schedule.time, 'HH:mm').isBefore(moment(), 'hour')) {
          this.schedule.time = '';
        }
      }
    },
    show() {
      this.showModalBroadcast = this.show;
      this.v$.form.$touch();
    },
    'v$.form.recipient_type.$model'(newValue) {
      if (newValue === 'promo') {
        this.form.page_destination = '/promo';
        this.getListPromo();
      } else {
        this.form.page_destination = ''; 
      }
    }
  },
};
</script>